import PageLinkComponent from '@/components/page/linkComponent';
import { mutateGraphQL, queryGraphQL } from '@/data/apollo';
import { CommercesAddMergedTo } from '@/data/commerce/commerce.graphql';
import { InvoiceDuplicate, InvoiceWrite } from '@/data/commerce/invoice.graphql';
import { AgreementsRead } from '@/graphql/agreement.graphql';
import { LineItemsRead } from '@/graphql/lineItem.graphql';
import SyncToClover from '@/helpers/syncToClover';
import useAccountType from '@/helpers/useAccountType';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import { PLATFORM_ENDPOINT } from '@/pages/api/processor/clover';
import { stayOpen } from '@/pages/dashboard/commerce/components/tableHelpers';
import { extractExternalId } from '@/pages/dashboard/commerce/components/utils';
import { deleteMergedTo, LineItemsFetch } from '@/pages/dashboard/commerce/invoices/actions/invoiceUtils';
import CommerceItemsUpdating from '@/pages/dashboard/commerce/invoices/commerceItemsUpdating';
import usePermissions, { permissions } from '@/providers/auth/usePermissions';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModal } from '@/providers/modal';
import {
	MutationCommercesAddMergedToArgs,
	MutationInvoiceDuplicateArgs,
	MutationInvoiceWriteArgs,
	Order,
	QueryAgreementsReadArgs,
	QueryLineItemsReadArgs,
	Staff,
} from '@/types/schema';
import { isSuperAdmin } from '@/utils/isSuperAdmin';
import {
	CancelRounded as CancelRoundedIcon,
	ConfirmationNumber as ConfirmationNumberIcon,
	CopyAll as CopyAllIcon,
	Delete as DeleteIcon,
	Print as PrintIcon,
	Sync as SyncIcon,
	SyncProblem as SyncProblemIcon,
} from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import axios from 'axios';
import { isEmpty, isString, pick } from 'lodash-es';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import process from 'process';
import { updateStock } from '../../orders/utils';

export const getDeleteBtnText = ( invoice: Order ) => {
	const isStocked = invoice.metadata?.stock;
	let btnText = '';
	if ( isStocked ) {
		btnText = invoice.externalId ? 'Reverse stock & remove from Clover' : 'Reverse stock & delete';
	} else {
		btnText = invoice.externalId ? 'Remove from Clover & Invoiss' : 'Remove from Invoiss';
	}
	return btnText;
};

export function DeleteInfo( { t } ) {
	return (
		<Stack spacing={1}>
			<Typography>{t( 'common:delete-confirmation' )}</Typography>
			<Typography>{t( 'common:delete-options' )}</Typography>
			<Typography>1. {t( 'common:delete-clover-confirmation' )}</Typography>
			<Typography>2. {t( 'common:delete-invoiss-confirmation' )}</Typography>
		</Stack>
	);
}

export const deleteInvoice = async ( invoice: Order, enqueueSnackbar: any, t: any, deleteFromClover = false ) => {
	try {
		
		if ( deleteFromClover ) {
			await axios.post( '/api/processor/manage/deleteOrder', { id: invoice.id } );
		}
		await mutateGraphQL<MutationInvoiceWriteArgs>( {
			mutation : InvoiceWrite,
			variables: {
				id    : invoice.id,
				method: 'Deleted Invoice',
				input : { deletedAt: new Date() },
			},
		} );
		await deleteMergedTo( [ invoice ] );
		
		enqueueSnackbar( `${t( 'common:invoice' )} ${t( 'common:delete-success' )}` );
	} catch {
		enqueueSnackbar( 'Error: Invoice could not be deleted', { variant: 'error' } );
		
	}
	
};

export function MoreActions( invoice: Order, single?: boolean ) {
	const { t } = useTranslation();
	const confirmDialog = useConfirmDialog();
	const editable = usePermissions( permissions.invoices.write );
	const { staff, user } = useUserInfo();
	const { enqueueSnackbar } = useSnackbar();
	const router = useRouter();
	const superAdmin = isSuperAdmin( user );
	const { showModal } = useModal();
	const isCloverAccount = useAccountType( 'CLOVER' );
	const isAccountInvoice = invoice.type === 'ACCOUNT';
	
	const cancelInvoice = async ( invoice: Order ) => {
		await mutateGraphQL<MutationInvoiceWriteArgs>( {
			mutation : InvoiceWrite,
			variables: {
				id    : invoice.id,
				method: invoice.status === 'CANCELLED' ? 'Marked UnCancel' : 'Marked Cancel',
				input : { cancelled: invoice.status !== 'CANCELLED' },
			},
		} );
	};
	
	return [ {
		name   : t( 'common:ticket' ),
		details: t( 'common:recommend-printer' ),
		icon   : <ConfirmationNumberIcon/>,
		props  : {
			component: PageLinkComponent,
			href     : `/api/preview/print/${invoice.id}/ticket`,
			target   : '_blank',
		},
	}, invoice.externalId && invoice.gateway?.external === 'CLOVER' && {
		name   : t( 'common:print-to-clover-order' ),
		icon   : <PrintIcon/>,
		onClick: async () => {
			await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/notification/printCloverOrder`, {
				orderId  : invoice.id,
				gatewayId: invoice.gateway?.id,
			} ).catch( () => null );
			
		},
	}, invoice.externalId && invoice.gateway?.external === 'CLOVER' && {
		name   : t( 'common:print-to-clover-receipt' ),
		icon   : <PrintIcon/>,
		onClick: async () => {
			await axios.post( '/api/notification/printNotification', {
				orderId: invoice.id,
			} );
		},
	}, isCloverAccount && {
		name   : t( 'common:sync' ),
		icon   : <SyncIcon/>,
		onClick: async () => {
			try {
				await SyncToClover( invoice );
				await updateStock( invoice, staff!.company, 'manualSyncStock', enqueueSnackbar );
				
			} catch ( e ) {
				const cloverErrors = e?.response.data?.cloverErrors;
				if ( cloverErrors ) {
					throw isString( cloverErrors )
						? cloverErrors
						: cloverErrors?.error?.message || cloverErrors?.message || 'An error has occurred. Clover.com';
				} else {
					enqueueSnackbar( t( 'common:sync-clover-error' ), { variant: 'default' } );
				}
			}
		},
	}, isCloverAccount && invoice.externalId && ![ 'PAID', 'PARTIALLY_PAID' ].includes( invoice.status ) && {
		name   : t( 'common:unsync' ),
		icon   : <SyncProblemIcon/>,
		onClick: async () => {
			const value = await confirmDialog( {
				title  : t( 'common:unsync' ),
				message: t( 'common:confirm-unsync-invoice' ),
			} );
			if ( !value ) return;
			try {
				await axios.post( '/api/processor/manage/deleteOrder', { id: invoice.id } );
				
				await mutateGraphQL<MutationInvoiceWriteArgs>( {
					mutation : InvoiceWrite,
					variables: {
						id    : invoice.id,
						method: 'Un-synced Invoice',
						input : {
							externalId   : null,
							gateway      : null,
							number       : null,
							overrideTotal: 0,
						},
					},
				} );
				enqueueSnackbar( t( 'common:unsync-success' ), { variant: 'success' } );
			} catch {
				enqueueSnackbar( 'Error: The invoice could not be unsynced.', { variant: 'error' } );
			}
		},
	}, editable && isAccountInvoice && superAdmin && {
		name   : 'Retrieve Merged Invoices',
		icon   : <CancelRoundedIcon/>,
		onClick: async () => {
			const { lineItemsRead } = await queryGraphQL<QueryLineItemsReadArgs>( {
				query    : LineItemsFetch,
				variables: { options: { limit: 2000, filter: { order: invoice.id } } },
			} );
			if ( isEmpty( lineItemsRead?.items ) ) {
				enqueueSnackbar( 'No line items', { variant: 'default' } );
				return;
			} else {
				const externalIds = lineItemsRead.items
					.filter( ( { name } ) => name.startsWith( '#' ) && name.length >= 12 )
					.map( ( lineItem ) => extractExternalId( lineItem.name ) );
				await mutateGraphQL<MutationCommercesAddMergedToArgs>( {
					mutation : CommercesAddMergedTo,
					variables: {
						id: invoice.id,
						externalIds,
					},
				} );
				enqueueSnackbar( 'Merged Invoices Retrieved successfully', { variant: 'success' } );
				
			}
			
		},
	}, editable && !invoice.standing && {
		name   : invoice.status === 'CANCELLED' ? t( 'common:uncancel' ) : t( 'common:cancel' ),
		icon   : <CancelRoundedIcon/>,
		onClick: async () => {
			const isCanceled = invoice.status === 'CANCELLED';
			
			if ( !isCanceled && invoice.metadata?.stock ) {
				const value = await confirmDialog( {
					title             : t( 'common:cancel' ),
					buttonText        : t( 'common:cancel' ),
					message           : 'Are you sure you want to cancel?',
					extraButtonText   : 'Reverse stock & cancel',
					onExtraButtonClick: async () => showModal( CommerceItemsUpdating, { maxWidth: 'lg' }, {
						commerce   : invoice,
						cbOnReverse: () => cancelInvoice( invoice ),
					} ),
				} );
				
				if ( !value ) return;
				await cancelInvoice( invoice );
				
			} else {
				const value = await confirmDialog( {
					title     : isCanceled ? t( 'common:uncancel' ) : t( 'common:cancel' ),
					buttonText: isCanceled ? t( 'common:uncancel' ) : t( 'common:cancel' ),
					message   : `Are you sure you want to ${isCanceled ? 'uncancel' : 'cancel'}?`,
				} );
				if ( !value ) return;
				await cancelInvoice( invoice );
			}
			
		},
	}, editable && {
		name   : t( 'common:delete' ),
		icon   : <DeleteIcon/>,
		onClick: async () => {
			const btnText = getDeleteBtnText( invoice );
			
			const value = await confirmDialog( {
				title             : `${t( 'common:delete' )} #${invoice.metadata?.customNumber || invoice.number || invoice.externalId || ''}`,
				buttonText        : t( 'common:delete' ),
				message           : btnText ? <DeleteInfo t={t}/> : t( 'common:delete-confirmation' ),
				extraButtonText   : btnText,
				onExtraButtonClick: btnText ? async () => {
					showModal( CommerceItemsUpdating, {
						maxWidth: 'lg',
						onClose : ( event, reason ) => stayOpen( event, reason ),
					}, {
						commerce   : invoice,
						cbOnReverse: () => deleteInvoice( invoice, enqueueSnackbar, t, Boolean( invoice.externalId ) ),
					} );
				} : undefined,
			} );
			
			if ( !value ) return;
			await deleteInvoice( invoice, enqueueSnackbar, t, false );
			if ( single ) await router.push( '/dashboard/commerce/invoices' );
		},
	} ];
}

export function RecurringActions( invoice: Order, staff: Staff, router ) {
	
	return invoice.status === 'STANDING' && invoice.standingActive ? [ {
		name   : 'Add to history',
		icon   : <CopyAllIcon/>,
		onClick: async () => {
			try {
				const { invoiceDuplicate } = await mutateGraphQL<MutationInvoiceDuplicateArgs>( {
					mutation : InvoiceDuplicate,
					variables: { ids: [ invoice.id ] },
				} );
				
				const { lineItemsRead } = await queryGraphQL<QueryLineItemsReadArgs>( {
					query    : LineItemsRead,
					variables: { options: { limit: 1000, filter: { order: invoice.id } } },
				} );
				const lineItems = lineItemsRead?.items;
				
				const { agreementsRead } = await queryGraphQL<QueryAgreementsReadArgs>( {
					query    : AgreementsRead,
					variables: { options: { limit: 1000, filter: { order: invoice.id } } },
				} );
				const agreements = agreementsRead?.items;
				
				await mutateGraphQL<MutationInvoiceWriteArgs>( {
					mutation : InvoiceWrite,
					variables: {
						id          : invoiceDuplicate.id,
						customNumber: Boolean( staff.company.metadata?.customNumber ),
						method      : 'Added to History',
						input       : {
							type    : invoiceDuplicate.type,
							metadata: {
								sendReminder      : staff.company.metadata?.dueReminder,
								cardFee           : false,
								cashDiscount      : false,
								enableCashDiscount: staff.company.metadata?.cashDiscount > 0,
								cardInfo          : {},
								mergedTo          : null,
								qbSyncToken       : null,
								qbClientId        : null,
								stock             : false,
								paymentByInfo     : null,
								paymentSignatures : null,
								paymentNote       : null,
								sentDates         : null,
								reminderDates     : null,
								signatureLine     : staff.company.metadata?.signatureLine,
								mergedSignatures  : null,
								originalCommerce  : invoice.id,
							},
							standingData  : null,
							standingActive: null,
							standingDate  : null,
							lineItems     : lineItems?.map( ( lineItem ) => ( {
								...pick( lineItem, [
									'name',
									'price',
									'image',
									'unit',
									'quantity',
									'tax',
									'metadata',
									'externalId',
								] ),
								description   : `${lineItem.description || ''}`,
								modifierGroups: lineItem.modifierGroups?.map( ( { id } ) => id ),
								prices        : lineItem.prices?.map( ( price ) =>
									pick( price, [
										'name',
										'isPercent',
										'value',
										'quantity',
										'metadata',
										'externalId',
									] ) ),
								uom     : lineItem.uom?.id || null,
								item    : lineItem.item?.id || null,
								category: lineItem.category?.id || null,
							} ) ),
							agreements: agreements?.map( ( agreement ) => ( {
								...pick( agreement, [
									'title',
									'body',
									'requireSignature',
									'expiration',
								] ),
								company: agreement.company.id || staff.company.id,
							} ) ),
							prices: invoiceDuplicate.prices && !isEmpty( invoiceDuplicate.prices )
								? invoiceDuplicate.prices.filter( ( { name } ) => name !== 'Card Processing Fee' && name !== 'Cash Discount' && name !== 'Credit' )
									.map( ( { id } ) => ( { id } ) )
								: [],
						},
					},
				} );
				
				await router.push( `/dashboard/commerce/invoices/${invoiceDuplicate.id}/edit` );
			} catch ( e ) {
				throw e;
			}
		},
	} ] : [];
}
