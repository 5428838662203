import EnhancedDisplay from '@/components/enhancedDisplay';
import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import InputStepper from '@/components/form/fields/InputStepper';
import FormTextField from '@/components/form/fields/textField';
import { mutateGraphQL } from '@/data/apollo';
import { axiosClient } from '@/data/axios';
import FormGraphqlProvider from '@/data/query/formGraphqlProvider';
import { CommerceRead_UpdateStockQuery } from '@/generated/graphql';
import { getStockState } from '@/helpers/inventory';
import StockQuickEdit from '@/pages/dashboard/commerce/components/stockQuickEdit';
import { deleteInvoice } from '@/pages/dashboard/commerce/invoices/actions/moreActions';
import { useModalControls } from '@/providers/modal';
import { ResponsiveModalContainer } from '@/providers/modal/responsiveModal';
import { isNumberEqual } from '@/utils/isNumberEqual';
import { gql } from '@apollo/client';
import { Box, ButtonProps, ListItemText, Stack, Typography, useTheme } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import Bluebird from 'bluebird';
import { filter } from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';
import uniqByFp from 'lodash/fp/uniqBy';
import { isEmpty, pick } from 'lodash-es';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

type LineItemStock = Omit<CommerceRead_UpdateStockQuery['commerceRead']['lineItems'][0], 'soldQuantity'> & {
	pendingQuantity: number,
	soldQuantity: number
};
type OrderStock = Omit<CommerceRead_UpdateStockQuery['commerceRead'], 'lineItems'> & {
	lineItems: LineItemStock[]
};

function UpdateStockButton( { text, buttonProps, stockProgress }: {
	stockProgress: number,
	text: string,
	buttonProps?: ButtonProps
} ) {
	const theme = useTheme();
	
	const isDarkMode = theme.palette.mode === 'dark';
	
	return (
		<AsyncLoadingButton
			key='update-stock'
			color='primary'
			variant={isDarkMode ? 'outlined' : 'contained'}
			progress={stockProgress}
			sx={{
				position: 'relative',
				overflow: 'hidden',
			}}
			{...buttonProps}>
			{text}
		</AsyncLoadingButton>
	);
}

function StockButton( {
	type,
	disabled = false,
	lineItem,
	onClick,
}: {
	type: 'UPDATE' | 'REVERSE',
	lineItem: LineItemStock,
	disabled?: boolean,
	onClick: () => void
} ) {
	const update = type === 'UPDATE';
	const shouldDisable = disabled
		|| !lineItem.uom?.id
		|| update && isNumberEqual( lineItem.pendingQuantity, 0 )
		|| !update && lineItem.soldQuantity === 0;
	
	return (
		<Box>
			<AsyncLoadingButton
				key='action'
				variant='contained'
				disabled={shouldDisable}
				color={update ? 'primary' : 'warning'}
				onClick={onClick}>
				{update ? 'Update Stock' : 'Reverse Sold Stock'}
			</AsyncLoadingButton>
		</Box>
	);
}

export default function CommerceItemsUpdating( { commerce, cbOnReverse }: {
	commerce: { id: string }, cbOnReverse?: () => Promise<void>
} ) {
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const router = useRouter();
	const queryClient = useQueryClient();
	const { closeModal } = useModalControls();
	const [ stockProgress, setStockProgressAtom ] = useState( 0 );
	
	const [ isUpdatingStock, setIsUpdatingStock ] = useState( false );
	const updateProgress = ( progress: number ) => {
		setStockProgressAtom( progress );
	};
	
	const updateAllStock = async ( { lineItems, action }: {
		lineItems: LineItemStock[],
		action: 'UPDATE' | 'REVERSE'
	} ) => {
		setIsUpdatingStock( true );
		const update = action === 'UPDATE';
		
		try {
			if ( isEmpty( lineItems ) ) {
				enqueueSnackbar( `No items to ${update ? 'update' : 'reverse'} stock.`, { variant: 'info' } );
				return;
			}
			
			const payload = lineItems.map( ( lineItem ) => {
				const selectedUom = lineItem.uom;
				if ( !selectedUom ) throw new Error( `No valid UOM found for ${lineItem.name || ''}(${lineItem.id})` );
				return {
					actionType       : update ? 'UPDATE' : 'REVERSE',
					absQuantityChange: update ? lineItem.pendingQuantity : lineItem.soldQuantity,
					action           : `Commerce Stock Batch ${action}`,
					lineItem         : pick( lineItem, [ 'id', 'quantity', 'soldQuantity' ] ),
					item             : { externalId: lineItem.item?.externalId },
					uom              : {
						id      : selectedUom.id,
						selected: selectedUom.selected || false,
						quantity: selectedUom.quantity || 0,
					},
				};
			} );
			const onUpdateSuccess = ( { progress }: { progress: number } ) => {
				updateProgress( progress );
			};
			
			let completed = 0;
			
			await Bluebird.map(
				payload,
				async ( lineItem ) => {
					await axiosClient.post( '/api/user/inventory/updateCommerce', lineItem );
					completed++;
					onUpdateSuccess( { progress: completed / payload.length * 100 } );
				}, { concurrency: 2 },
			);
			
			await queryClient.invalidateQueries( [ 'commerce', commerce.id ] );
			
			enqueueSnackbar( `Stock is ${update
				? 'updated'
				: 'reversed'} successfully for the items.`, { variant: 'success' } );
		} finally {
			setIsUpdatingStock( false );
		}
	};
	
	const updateSingleStock = async ( { lineItem, action }: {
		lineItem: LineItemStock,
		action: 'UPDATE' | 'REVERSE'
	} ) => {
		setIsUpdatingStock( true );
		const update = action === 'UPDATE';
		if ( !lineItem.uom ) throw new Error( `Invalid UOM for ${lineItem.name || ''}(${lineItem.id})` );
		try {
			await axiosClient.post( '/api/user/inventory/updateCommerce', {
				actionType       : update ? 'UPDATE' : 'REVERSE',
				absQuantityChange: update ? lineItem.pendingQuantity : lineItem.soldQuantity,
				action           : `Commerce Stock Single ${action}`,
				lineItem         : pick( lineItem, [ 'id', 'quantity', 'soldQuantity' ] ),
				item             : { externalId: lineItem.item?.externalId },
				uom              : {
					id      : lineItem.uom.id,
					selected: lineItem.uom.selected || false,
					quantity: lineItem.uom.quantity || 0,
				},
			} );
			
			await queryClient.invalidateQueries( [ 'commerce', commerce.id ] );
		} finally {
			setIsUpdatingStock( false );
		}
	};
	
	async function updateCommerceState( order: OrderStock ) {
		const { allStocked } = getStockState( order.lineItems );
		if ( order.metadata.stock !== allStocked ) {
			console.log( 'Updating order state' );
			await mutateGraphQL( {
				mutation: gql`
					mutation CommerceWrite($id: String, $input: OrderValidator) {
						commerceWrite(id: $id, input: $input) {
							id
						}
					}
				`,
				variables: {
					id   : order.id,
					input: { metadata: { stock: allStocked } },
				},
			} );
		}
	}
	
	return (
		<FormGraphqlProvider<OrderStock>
			enableReinitialize
			id={commerce.id}
			queryKey={[ 'commerce', commerce.id ]}
			query={gql`
				query CommerceRead_UpdateStock($id: String!) {
					commerceRead(id: $id) {
						id
						number
						metadata
						externalId
						lineItems {
							id
							name
							unit
							sku
							price
							cost
							quantity
							stocked
							soldQuantity
							metadata
							linkedOrder {
								id
								type
								number
								metadata
							}
							item {
								id
								externalId
								uoms {
									id
									name
									sku
									cost
									price
									quantity
									selected
								}
							}
							uom {
								id
								name
								sku
								cost
								price
								quantity
								selected
							}
						}
					}
				}
			`}
			select={( order ) => ( {
				...order,
				lineItems: order.lineItems.map( ( lineItem ) => ( {
					...lineItem,
					uom            : lineItem.uom || lineItem.item?.uoms?.find( ( uom ) => uom.selected ),
					soldQuantity   : lineItem.soldQuantity || 0,
					pendingQuantity: lineItem.quantity - ( lineItem.soldQuantity || 0 ),
				} ) ),
			} )}
			onSuccess={updateCommerceState}>
			{( formik ) => {
				const lineItems = formik.values.lineItems;
				const validLineItems = lineItems.filter( ( lineItem ) => lineItem.uom?.id );
				const batchLinesToUpdate = pipe( validLineItems,
					filter( ( line ) => Boolean( line.pendingQuantity ) ),
					uniqByFp( ( line ) => line.uom?.id ),
				);
				const batchLinesToReverse = pipe( validLineItems,
					filter( ( line ) => Boolean( line.soldQuantity ) ),
					uniqByFp( ( line ) => line.uom?.id ),
				);
				
				return (
					<ResponsiveModalContainer
						title={`${t( 'commerce:update-stock' )} #${formik.values.metadata?.customNumber || formik.values.number}`}
						secondaryTitle={t( 'common:update-commerce-title' )}
						actionItems={(
							<Stack direction='row' alignItems='center' spacing={1}>
								{cbOnReverse && (
									<AsyncLoadingButton
										variant='contained'
										color='error'
										onClick={async () => {
											await deleteInvoice( formik.values as any, enqueueSnackbar, t, Boolean( formik.values.externalId ) );
											closeModal();
											await router.push( '/dashboard/commerce/invoices' );
										}}>
										Delete Only
									</AsyncLoadingButton>
								)}
								<UpdateStockButton
									key='reverseStock'
									text='Reverse All Stock'
									stockProgress={stockProgress}
									buttonProps={{
										disabled: !batchLinesToReverse.length,
										onClick : () => updateAllStock( { lineItems: batchLinesToReverse, action: 'REVERSE' } ),
									}}
								/>
								<UpdateStockButton
									key='updateStock'
									text='Update All Stock'
									stockProgress={stockProgress}
									buttonProps={{
										variant : 'contained',
										color   : 'primary',
										disabled: !batchLinesToUpdate.length,
										onClick : () => updateAllStock( { lineItems: batchLinesToUpdate, action: 'UPDATE' } ),
									}}
								/>
							</Stack>
						)}
						onClose={async () => {
							if ( cbOnReverse ) {
								const { allStocked, partiallyStocked } = getStockState( lineItems );
								if ( !allStocked && !partiallyStocked ) await cbOnReverse();
							}
							closeModal();
						}}>
						<EnhancedDisplay
							data={formik.values.lineItems}
							extraData={isUpdatingStock}
							listProps={{
								renderRow: ( lineItem, index ) => (
									<Stack direction='row' width='100%'>
										<ListItemText
											primary={lineItem.name}
											primaryTypographyProps={{ variant: 'h5' }}
											secondary={(
												<Stack spacing={1}>
													<Stack>
														<Typography>
															UOM Qty: {lineItem.uom?.quantity || '-'}
														</Typography>
														<Typography>
															Item Qty: {lineItem.quantity}
														</Typography>
														<Typography>
															Unit: {lineItem.unit}
														</Typography>
													</Stack>
													<FormTextField
														disabled={lineItem.stocked}
														placeholder={t( 'common:quantity-sold' )}
														label={t( 'common:quantity-sold' )}
														type='number'
														name={`lineItems.${index}.soldQuantity`}
														format={( value ) => +value}
														sx={{
															'.MuiFormLabel-root': { pl: 0 },
														}}
														onFocus={( e ) => e.target.select()}
													/>
												</Stack>
											)}
										/>
										<StockButton
											type='UPDATE'
											lineItem={lineItem}
											disabled={isUpdatingStock}
											onClick={() => updateSingleStock( { lineItem, action: 'UPDATE' } )}
										/>
										<StockButton
											type='REVERSE'
											lineItem={lineItem}
											disabled={isUpdatingStock}
											onClick={() => updateSingleStock( { lineItem, action: 'UPDATE' } )}
										/>
									</Stack>
								),
							}}
							tableProps={{
								hover  : false,
								headers: [
									t( 'common:items' ),
									'Available',
									t( 'common:ordered' ),
									t( 'common:sold' ),
									'Pending',
									'Update Stock',
									'Reverse Stock',
								],
								columns: ( lineItem, index ) => [
									<Typography key='name'>
										{lineItem.name}
									</Typography>,
									<StockQuickEdit
										key='uom-quantity'
										uom={lineItem.uom}
										externalId={lineItem.item?.externalId}
										disabled={isUpdatingStock}
										onMutate={() => setIsUpdatingStock( true )}
										onFinish={() => setIsUpdatingStock( false )}
										onSuccess={() => queryClient.invalidateQueries( [ 'commerce', commerce.id ] )}
									/>,
									<Stack key='unit-quantity' direction='row' alignItems='center' spacing={1}>
										<Typography>{lineItem.quantity} </Typography>
										<Typography>{lineItem.unit}</Typography>
									</Stack>,
									<Stack key='sold-quantity' direction='row' alignItems='center' spacing={1}>
										<Typography>{lineItem.soldQuantity} </Typography>
										<Typography>{lineItem.unit}</Typography>
									</Stack>,
									lineItem.quantity - lineItem.soldQuantity <= 0
										? <Typography key='received-quantity' align='center'>NONE</Typography>
										: (
											<InputStepper
												key='pending-quantity'
												disabled={lineItem.quantity - lineItem.soldQuantity <= 0}
												name={`lineItems.${index}.pendingQuantity`}
												minVal={0}
												maxVal={lineItem.quantity - lineItem.soldQuantity}
												width={120}
												onFocus={( e ) => e.target?.select()}
											/>
										),
									<StockButton
										key='update-stock'
										type='UPDATE'
										lineItem={lineItem}
										disabled={isUpdatingStock}
										onClick={() => updateSingleStock( { lineItem, action: 'UPDATE' } )}
									/>,
									<StockButton
										key='reverse-stock'
										type='REVERSE'
										lineItem={lineItem}
										disabled={isUpdatingStock}
										onClick={() => updateSingleStock( { lineItem, action: 'REVERSE' } )}
									/>,
								],
								cellProps: [
									{ width: '30%' },
									{ width: '12%' },
									{ width: '10%' },
									{ width: '10%' },
									{ width: '10%', align: 'center' },
									{ width: '10%', align: 'center' },
									{ width: '10%', align: 'center' },
								],
							}}
						/>
					</ResponsiveModalContainer>
				
				);
			}}
		</FormGraphqlProvider>
	);
}
